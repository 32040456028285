import React, { useEffect, useRef, useState } from "react";
import { BottomButtons } from "../../components/Medecins/BottomButtons";
import { Steps } from "../../components/Medecins/Steps";
import { TextFieldBasic } from "../../components/Medecins/TextFieldBasic";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../globarvariable";
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from "react-redux";
import { getUserId } from "../../actions";
import { ButtonAction } from "../../components/Medecins/ButtonAction";
import axios from 'axios';
import { ClipLoader } from "react-spinners";
import { Logo } from "../../components/Patients/Logo";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const utilsScriptUrl = "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";
const libraries = ['places'];


export const DonnesPatient = (): JSX.Element => {

  const [buttonState, setButtonState] = useState('disabled');
  const [PeButtonState, setPeButtonState] = useState('disabled');
  const [lastname, setLastName] = useState('');
  const [firstname, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [stateStep1, setStateStep1] = useState('in-progress');
  const [stateStep2, setStateStep2] = useState('to-do');
  const [stateStep3, setStateStep3] = useState('to-do');
  const [id, setId] = useState('');
  const [userId, setUserId] = useState('');
  const [dataPatients, setDataPatients] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [following, setFollowing] = useState(false);
  const [notFollowing, setNotFollowing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const [isScrolled, setIsScrolled] = useState(false);
  //const userId = useSelector(state => state.userIdData);
  const dispatch = useDispatch();
  const inputRef = useRef();
  let iti = null;

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAFMZ7sAnfmjTgceZ2h4pfmkQNAmdo_F54',
    libraries
});

const handlePlaceChanged = () => { 
  const [ place ] = inputRef.current.getPlaces();
  if(place) { 
    setAddress(place.formatted_address);
  } 
}


  const handleChangePatient = (e) => {
    setId(e.target.value)
    setPeButtonState('initial');
  }

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  }

  const handleChangeFirstName = (e) =>{
    setFirstName(e.target.value);
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  
  const handleChangePhone = (status, value, countryData, number, id) => {
    const selectedFlagElement = document.querySelector('.selected-flag[title]')
    if (selectedFlagElement) {
      const title = selectedFlagElement.getAttribute('title');
      const match = title.match(/:\s\+(\d+)/);
      let phoneNumber = null;
      if (match) {
        if(value[0] == 0){
            phoneNumber = value.slice(1)
        }
        else{
          phoneNumber = value
        }

        setPhone(`+${match[1]}`+phoneNumber);
      }
    }
  }

  const handleBlurPhone = (status, value, countryData, number, id) => {
    const selectedFlagElement = document.querySelector('.selected-flag[title]')
    if (selectedFlagElement) {
      const title = selectedFlagElement.getAttribute('title');
      const match = title.match(/:\s\+(\d+)/);
      if (match) {
        setPhone(`+${match[1]}`+value.slice(1));
      }
    }
    console.log('blur'+phone)
  }

  const handleChangeAddress = (e) => {
    setAddress(e.target.value);
  }

  const handleChangePostalCode = (e) => {
    setPostalCode(e.target.value);
  }

  const handleChangeCity = (e) => {
    setCity(e.target.value);
  }

  const handleSubmit = () => {
    setLoading(true);
    // Create an object with the data
    const formData = {
      last_name: lastname,
      first_name: firstname,
      email: email.toLowerCase(),
      address: address,
      /*postal_code: postalCode,
      city: city,*/
      role: 'Patient',
      phone_number: phone
    };

    console.log('formData'+JSON.stringify(formData));

    // Make a POST request to the API endpoint
    fetch(api_url+'api/patients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.id){
          setId(data.id);
          setUserId(data.id);
          dispatch(getUserId(data.id));
          
          if(data.followed === true){
              setFollowing(true);
          }
          else{
              setNotFollowing(true);
          }
            if(localStorage.getItem('center_id')){
              const formData = {
                center_id: localStorage.getItem('center_id'),
              };
              fetch(api_url+'api/users/'+id, {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(formData),
              })
    
              
              setTimeout(() => {
                navigate('/medecin/prescription');
            }, 3000);
            }
            else{
              setTimeout(() => {
                  navigate('/medecin/choisir-un-centre');
              }, 3000);
            }

        }
        else{
            setError(true);
            setErrorMessage(data.detail)
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });


      
      setTimeout(() => {
        setLoading(false);
      }, 2000);
  };

  const handlePeSubmit = (e) => {
        dispatch(getUserId(id));
        if(localStorage.getItem('center_id')){
          const formData = {
            center_id: localStorage.getItem('center_id'),
          };
          fetch(api_url+'api/users/'+id, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
          })

          navigate('/medecin/prescription');
        }
        else{
        navigate('/medecin/choisir-un-centre');
        }
  };

  useEffect(() => {
    localStorage.setItem('user_id', userId);
  }, [userId]);

  useEffect(() => {
    localStorage.setItem('user_id', id);
  }, [id]);


  useEffect(() => {
    if(lastname != '' && firstname != '' && email !='' && address !=''){
      setButtonState('initial');
    }
    else{
      setButtonState('disabled');
    }
  }, [lastname,firstname,email,address]);
  

  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/patients?limit=50', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
      }).then((response) =>{
        setDataPatients(response.data.patients);
      })
      //
    }

    fetchData();

  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Define your scroll condition here
      if(scrollTop > 200){
        setIsScrolled(true);
      } 
      else{
        setIsScrolled(false);
      }
      
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 


const logout = () => {
  localStorage.removeItem('accessToken');
  navigate('/');
}

  return (
    
    <>
    <header className="p-3 mb-3 prescription-header">
    <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
  </header> 
    <div className="donnes-patient"> 
    
      <div className="div-2"> 
        <Steps
          className={`steps-instance ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-3"
          renseignezLesClassName="steps-2"
          state={stateStep1 === "in-progress" ? "in-progress" : "done"}
          text="PATIENT"
          type="patient"
        />
        <Steps
          className={`steps-4 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-3"
          renseignezLesClassName="steps-2"
          state={stateStep2 === "in-progress" ? "in-progress" : "to-do"}
          text="CENTRE APA"
          text1="Choisissez le centre"
          type="patient"
        />
        <Steps
          className={`steps-5 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-3"
          renseignezLesClassName="steps-2"
          state={stateStep3 === "in-progress" ? "in-progress" : "to-do"}
          text="PRESCRIPTION"
          text1="Éditez la prescription"
          type="patient"
        />
        <h1 className="title-patient">Renseignez les données de votre patient</h1>
        { notFollowing === true && <div className="notFollowed">
            <p><strong>Ce patient ne sera pas suivi par APAORA dans le cadre de l'étude clinique</strong></p>
        </div> }
        { following === true && <div className="followed">
            <p><strong>Ce patient sera suivi par APAORA dans le cadre de l'étude clinique</strong></p>
        </div>}

        {/* To choose existing patient */}
        <div className="text-wrapper-3">Patient existant</div>
        <select className="form-select existant-patient" onChange={handleChangePatient}>
        <option></option>
              {dataPatients.map((item, index) => (
                <option key={index} value={item.id}>{item.first_name} {item.last_name}</option>
              ))}
      </select>
      <ButtonAction
          className="button-action-instance patient-exist-button"
          color="dark"
          size="primary"
          state={PeButtonState == "disabled" ? "disabled" : "initial"}
          text="Valider et continuer"
          onClick={handlePeSubmit}
        />

      {/* To create new patient  */}
        <div className="text-wrapper-2">Nouveau patient</div>
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-6"
          state="initial"
          text="Nom"
          type="classic"
          id='firstname'
          onBlur={handleChangeLastName}
        />
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-8"
          state="initial"
          text="Prénom"
          type="classic"
          id='lastname'
          onBlur={handleChangeFirstName}
        />
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-7"
          state="initial"
          text="Email"
          type="email"
          id='email'
          onBlur={handleChangeEmail}
        />
        <IntlTelInput
          containerClassName="text-field-basic  text-field-basic-9 phone intl-tel-input"
          inputClassName="form-control"
          preferredCountries={['fr']}
          onPhoneNumberChange={handleChangePhone}
          onPhoneNumberBlur={handleChangePhone}
        />
        
        {
          isLoaded && 
          <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged}
        >
            <input
                type="text"
                className="form-control searchaddress-patient"
                placeholder="Entrez une adresse"
                onChange={handleChangeAddress}
            />
        </StandaloneSearchBox>
        }

        <img className="line-4" alt="Line" src="/img/line-9.svg" />
        <div className="bottom-buttons bottom-buttons-instance">
        {error && <p className="error-message-insert">{errorMessage}</p>}
        {loading && <ClipLoader color="#123abc" loading={loading} />}
        <ButtonAction
          className="button-action-instance"
          color="dark"
          size="primary"
          state={buttonState == "disabled" ? "disabled" : "initial"}
          text="Valider et continuer"
          onClick={handleSubmit}
        />
        </div>
      </div>
    </div>
    </>
  );
};
