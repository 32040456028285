import { isNullOrUndefined } from "html5-qrcode/esm/core";
import React from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ isLoggedIn, children, patient }) => {
  if (!isLoggedIn && patient == "patient") {
    return <Navigate to="/login" replace />;
  }
  else if(!isLoggedIn && patient == "eapa"){
    return <Navigate to="/login-eapa" replace />;
  }
  else if(!isLoggedIn && patient == "medecin"){
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoute;