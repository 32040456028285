import React, { useEffect, useState } from "react";
import { BottomButtons } from "../../components/Medecins/BottomButtons";
import { ButtonAction } from "../../components/Medecins/ButtonAction";
import { Steps } from "../../components/Medecins/Steps";
import { TagSelection } from "../../components/Medecins/TagSelection";
import { TextFieldBasic } from "../../components/Medecins/TextFieldBasic";
import { TextFieldNumber } from "../../components/Medecins/TextFieldNumber";
import "./style.css";
import Prescription from "../../components/Medecins/Prescription/Prescription";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { api_url } from "../../globarvariable";
import { getExerciseId } from "../../actions/exercise";
import { ClipLoader } from "react-spinners";
import { Logo } from "../../components/Patients/Logo/Logo";
import { confirmAlert } from "react-confirm-alert";
import { isNullOrUndefined } from "html5-qrcode/esm/core";

export const DonnesPrescription = (): JSX.Element => {
  const navigate = useNavigate();
  

  const [weekFrequency, setWeekFrequency] = useState(2);
  const [selectedExercise, setSelectedExercise] = useState<any[]>([]);
  const [selectedIntensity, setSelectedIntensity] = useState([]);
  const [precaution, setPrecaution ] = useState(0);
  const [idActivity, setIdActivity] = useState<any[]>([]);
  const [activityDuration, setActivityDuration] = useState<any[]>([]);
  const [typeId, setTypeId] = useState<any[]>([]);
  const [typeDescription, setTypeDescription] = useState<any[]>([]);
  const [intensityId, setIntensityId] = useState<any[]>([]);
  const [intensityDescription, setIntensityDescription] = useState<any[]>([]);
  const [serieNumber, setSerieNumber] = useState<any[]>([]);
  const [serieDuration, setSerieDuration] = useState<any[]>([]);
  const [precaution1, setPrecaution1] = useState<any[]>([]);
  const [precaution2, setPrecaution2] = useState<any[]>([]);
  const [precaution3, setPrecaution3] = useState<any[]>([]);
  const [precaution4, setPrecaution4] = useState<any[]>([]);
  const [precautionAutre, setPrecautionAutre] = useState<any[]>([]);
  const [prescriptionId, setPrescriptionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  //const patientId = useSelector((action : any) => action.userIdReducer);
  const patientId : any = localStorage.getItem('user_id');
  const accessToken = localStorage.getItem('accessToken');
  const [componentDataList, setComponentDataList] = useState([{ id: 1, text: 'Exercice 1' }]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [defineByEapa, setDefineByEapa] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const handleWeekFrequency = (e) => {
    setWeekFrequency(e.target.value);
}


const handleIdActivity = (value, i) => {
  const inputData: string[] = [...idActivity];
  if(value === 'None') {
    inputData.splice(i, 1);
  }else{
    inputData[i] = value;
  }
  setIdActivity(inputData);
}

const handleActivityDuration = (value, i) => {
  const inputData: string[] = [...activityDuration];
  inputData[i] = value;
  setActivityDuration(inputData);
}

const handleChooseExercise = (value, i) => {
  const inputData: string[] = [...typeId];
    inputData[i] = value;
  setTypeId(inputData);
}

const handleTypeDescription = (value, i) => {
  const inputData: string[] = [...typeDescription];
    inputData[i] = value;
    setTypeDescription(inputData);
}



const handleChooseIntensity = (value, i) => {
  const inputData: string[] = [...intensityId];
    inputData[i] = value;
    setIntensityId(inputData);
}

const handleIntensityDescription = (value, i) => {
  const inputData: string[] = [...typeDescription];
    inputData[i] = value;
    setIntensityDescription(inputData);
}


const handleSerieNumber = (value, i) => {
  const inputData: string[] = [...serieNumber];
    inputData[i] = value;
    setSerieNumber(inputData);
}

const handleSerieDuration = (value, i) => {
  const inputData: string[] = [...serieDuration];
    inputData[i] = value;
    setSerieDuration(inputData);
}

const handlePrecaution1 = (value, i) => {
  const inputData: string[] = [...precaution1];
    inputData[i] = value;
    setPrecaution1(inputData);
}

const handlePrecaution2 = (value, i) => {
  const inputData: string[] = [...precaution2];
    inputData[i] = value;
    setPrecaution2(inputData);
}

const handlePrecaution3 = (value, i) => {
  const inputData: string[] = [...precaution3];
    inputData[i] = value;
    setPrecaution3(inputData);
}

const handlePrecaution4 = (value, i) => {
  const inputData: string[] = [...precaution4];
    inputData[i] = value;
    setPrecaution4(inputData);
}
const handlePrecautionAutre = (value, i) => {
  const inputData: string[] = [...precautionAutre];
    inputData[i] = value;
    setPrecaution2(inputData);
}


useEffect(() => {
  if(!isNullOrUndefined(idActivity[componentDataList.length -1])  && !isNullOrUndefined(typeId[componentDataList.length -1]) && !isNullOrUndefined(intensityId[componentDataList.length -1])){ 
    setDisabled(false); 
  }
  else {
    setDisabled(true);
  }

}, [activityDuration,idActivity,typeId,intensityId, componentDataList]);


const handleClick = () => {

  setLoading(true);
  const exercisesJson: any[] = [];
  


  for (let i = 0; i < idActivity.length; i++) {

    const precaution: any[] = [];

    if(precaution1[i]){
      precaution.push({
        name: precaution1[i]
      })
    }
    if(precaution2[i]){
      precaution.push({
        name: precaution2[i]
      })
    }
    if(precaution3[i]){
      precaution.push({
        name: precaution3[i]
      })
    }
    if(precaution4[i]){
      precaution.push({
        name: precaution4[i]
      })
    }
    if(precautionAutre[i]){
      precaution.push({
        name: precautionAutre[i]
      })
    }


    exercisesJson.push({
      activity_duration: activityDuration[i],
      type_description: typeDescription[i],
      intensity_description: intensityDescription[i],
      serie_number: serieNumber[i],
      serie_duration: serieDuration[i],
      precautions: precaution,
      activity_id: idActivity[i],
      types: typeId[i],
      intensity_id: intensityId[i],
      define_by_eapa: defineByEapa[i]
    })
  }

  const prescriptionData  = {
    week_frequency: weekFrequency,
    patient_id: parseInt(patientId),
    exercises: exercisesJson  
  };

  
  fetch(api_url+'api/prescriptions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(prescriptionData),
  }).then((response) => response.json())
  .then((data) => {
    if(data.id){
      setPrescriptionId(data.id);
      dispatch(getExerciseId(data.id));
      localStorage.setItem('prescriptionId', data.id);
      localStorage.setItem('name', data.patient.first_name + data.patient.last_name)
      localStorage.setItem('doctor', data.doctor.first_name +' '+ data.doctor.last_name)
      navigate('/medecin/final-prescription');
    }
    
  })
  .catch((error) => {
    console.error('Error inserting data:', error);
  });

  setTimeout(() => {
    setLoading(false);
  }, 2000);
  
}


const duplicateComponent = () => {
  setDisabled(true);
  const lastComponent = componentDataList[componentDataList.length - 1];
  const newComponentId = lastComponent.id + 1;
  setComponentDataList([...componentDataList, { id: newComponentId, text: `Exercice ${newComponentId}` }]);
  console.log(disabled)
};

useEffect(() => { 
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    // Define your scroll condition here
    if(scrollTop > 200){
      setIsScrolled(true);
    } 
    else{
      setIsScrolled(false);
    }
    
  };

  // Attach the event listener
  window.addEventListener('scroll', handleScroll);

  // Detach the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []); 

const handleCancelledPrescription = () => {
  confirmAlert({
    title: 'Confirmation',
    
    message: 'Êtes-vous sûr de vouloir annuler la prescription?',
    buttons: [
      {
        label: 'Non',
        className: 'not-cancelled-prescription',
      },
      {
        label: 'Oui',
        className: 'cancelled-prescription',
        onClick: () => {
          localStorage.removeItem('center_id');
          navigate('/medecin');
        }
      }
    ]
  })
}

const handleCheckBox = (value, i) => {

  if(!isNullOrUndefined(idActivity[componentDataList.length -1])  && !isNullOrUndefined(typeId[componentDataList.length -1]) && !isNullOrUndefined(intensityId[componentDataList.length -1])){ 
    setDisabled(false); 
  }
  else{
    setDisabled(!disabled);
  }
    
    const inputData: string[] = [...defineByEapa];
    inputData[i] = value;
    setDefineByEapa(inputData);
}


  return (
    <>
    <header className="p-3 mb-3 prescription-header">
    <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
  </header> 
    <div className="donnes-prescription">
      <div className="div-2">
      <div className="row">
      <div className="col-md-3">
        <Steps
          className={`steps-instance ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          frameClassNameOverride="steps-4"
          iconEssentialTickIconEssentialTickClassName="steps-6"
          iconLocationIconLocationClassName="steps-5"
          marieChristineClassName="steps-3"
          mariechristineClassName="steps-3"
          state="done"
          text="PATIENT"
          type="patient"
        />
        <Steps
          className={`steps-7 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          frameClassNameOverride="steps-8"
          iconEssentialTickIconEssentialTickClassName="steps-6"
          iconLocationIconLocationClassNameOverride="steps-5"
          marieChristineClassName="steps-3"
          mariechristineClassName="steps-4"
          state="done"
          text1={
            <>
              30-42 Rue Jean Jaurès,
              <br />
              92270 Bois-Colombes
            </>
          }
          type="centre-APA"
        />
        <Steps
          className={`steps-9 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          iconEssentialTickIconEssentialTickClassName="steps-10"
          renseignezLesClassName="steps-3"
          state="in-progress"
          type="prescription"
        />
      </div>
      <div className="col-md-1">

      </div>
      <div className="col-md-8 define-prescription">
      <h1 className="title">Définissez la prescription APA pour ce patient.</h1>
      <h2 className="subtitle">Fréquence</h2>
            <div className="form-outline frequency">
                <input type="number" id="typeNumber" className="form-control"  min={0} value={weekFrequency} onChange={handleWeekFrequency}/>
                <label>séance(s) par semaine</label>
            </div>
        {componentDataList.map((componentData, i) => (
          <Prescription  key={componentData.id}  handleIdActivityChange={e=>handleIdActivity(e, i)} handleIdDurationChange={e=>handleActivityDuration(e, i)} handleTypeIdChange={e=>handleChooseExercise(e, i)}  handleTypeDescriptionChange={e=>handleTypeDescription(e, i)}  handleIntensityIdChange={e=>handleChooseIntensity(e, i)}  handleIntensityDescriptionChange={e=>handleIntensityDescription(e, i)} handleSerieNumberChange={e=>handleSerieNumber(e, i)} handleSerieDurationChange={e=>handleSerieDuration(e, i)} exercise={componentData.text} handlePrecaution1Change={e=> handlePrecaution1(e, i)} handlePrecaution2Change={e=> handlePrecaution2(e, i)} handlePrecaution3Change={e=>handlePrecaution3(e, i)} handlePrecaution4Change={e=>handlePrecaution4(e, i)} handlePrecautionAutreChange={e=> handlePrecautionAutre(e,i)} handleCheckboxChange={e => handleCheckBox(e,i)}/>
        ))}
       <button type="button" className="btn btn-outline-primary add-exercise" data-mdb-ripple-color="dark" onClick={duplicateComponent}>Ajouter un exercice</button>
      </div>
      </div>

      <div className="row bottom-buttons">
        <div className="col-md-12">
          <button type="button" className="btn btn-outline-primary" data-mdb-ripple-color="dark" onClick={handleCancelledPrescription}>Annuler la prescription <img src='/img/close-circle.png' /></button>
          {loading && <ClipLoader color="#123abc" loading={loading} />}  <button type="button" className="btn btn-primary" data-mdb-ripple-color="dark" onClick={handleClick} disabled={disabled}>Finaliser la prescription <img src='/img/arrow-right.png' /></button>
        </div>
    </div>
    </div>
    </div>
    </>
  );
};
