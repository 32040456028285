import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldBasic } from "../../components/Patients/TextFieldBasic";
import "./style.css";
import PasswordGenerator from "../../components/Patients/PasswordGenerator/PasswordGenerator";
import axios from "axios";
import { api_url } from "../../globarvariable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import ModalCentered from "../../components/Patients/Modal/Modal";

export const InscriptionEAPA = (): JSX.Element => {

  const[stateButton, setStateButton] = useState('disabled');
  const[lastName, setLastName] = useState('');
  const[firstName, setFirstName] = useState('');
  const[email, setEmail] = useState('');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const userId = useSelector((action : any) => action.userIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cgu, setCgu] = useState(false);
  const [confidentialite, setConfidentialite] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [valueCenter, setValueCentre] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [data, setData] = useState([]);
  const [centerId, setCenterId] = useState('');

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
    setPassword(value);
  };

  const handleSubmit =  async () => {
    setLoading(true);
    const formData = {
      last_name: lastName,
      first_name: firstName,
      email: email.toLowerCase(),
      password: password,
      center_id: centerId 
    };

    fetch(api_url+'api/eapas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.id){
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setShowModal(true);
        }
        else{
            setError(true);
            setErrorMessage(data.detail)
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });

  }

  useEffect(() => {

    if(lastName != '' && firstName != '' && email != '' && cgu === true && confidentialite ===true && centerId !== ''){
      setIsDisabled(false);
    }

  }, [lastName,firstName,email,cgu,confidentialite,centerId]);

  useEffect(() => {

    const fetchData = () => {
      const response =  axios.get(api_url+'api/users/'+userId, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
        setLastName(response.data.last_name);
        setFirstName(response.data.first_name);
        setEmail(response.data.email);
      })
      //
    }


    fetchData();

  }, []);

  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/centers' ,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) =>{
        //const filteredData = response.data.centers.filter(option =>option.name.toLowerCase().includes(valueCenter.toLocaleLowerCase()))
        setSuggestions(response.data.centers);
        setData(response.data.centers);
      })
      //
    }

    fetchData();


  }, []);

  const filteredList = () => {
    return suggestions.filter(item => {
        
        const center_name = item.name.toLowerCase().includes(valueCenter.toLocaleLowerCase());

        return center_name;
    })
}

  useEffect(() => {
    if(valueCenter){
       setSuggestions(filteredList);
    }
    else{
      setSuggestions(data);
    }
}, [valueCenter])


  const handleCheckbox = () => {
      setStateButton('initial');
  }

  const onClose = () => {
     setShowModal(false);
      navigate('/login-eapa');
  }

  const handleCGU = () => {
      if(cgu === false) {
         setCgu(true);
      }
      else{
        setCgu(false);
      }
  }

  const handleConfidentialite = () => {
    if(confidentialite === false) {
       setConfidentialite(true);
    }
    else{
      setConfidentialite(false);
    }
}

const handleChangeCentre = event => {
  setShowSuggestions(true);
  setValueCentre(event.target.value);
}

const handleSuggestionClick = (suggest, center_id) => {
  setValueCentre(suggest);
  setCenterId(center_id)
  setShowSuggestions(false);
}  


  return (
    <div className="inscription-mobile eapa">
      <div className="div-3">
        <p className="p">Pour commencer, nous allons créer votre compte.</p>
        <div className="text-wrapper-2">Vos informations personnelles</div>
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-instance"
          state="initial"
          text={lastName ? lastName :""}
          type="classic"
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Nom"
        />
        <TextFieldBasic
          background="transparent"
          className="design-component-instance-node"
          state="initial"
          text={firstName ? firstName : ""}
          type="classic"
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Prénom"
        />
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-2"
          state="initial"
          text={email ? email : ""}
          type="classic"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Adresse email"
        />

        <p className='text-centre'>Votre centre APA</p>
        <input type='text' placeholder='Nom du centre'  className="input-centre" value={valueCenter} onChange={handleChangeCentre}/>
        {showSuggestions && (
          <ul className="suggestions">
              {suggestions.map(suggestion => (
                <li key={suggestion.id} value={suggestion.id} onClick={() => handleSuggestionClick(suggestion.name, suggestion.id)}>{suggestion.name}</li>
              ))}
          </ul>
        )}
        <p className="cgu">
        <input type='checkbox' onChange={handleCGU} /> En créant un compte, vous acceptez nos <a href=''>CGU</a>
        </p>
        <p className="confidentialite">
        <input type='checkbox' onChange={handleConfidentialite}/> En créant un compte, vous avez lu et accepté la <a href=''>Politique de confidentialité</a>
        </p>
        <button
          className="button-action-instance sign-up btn btn-primary"
          color="dark"
          onClick={handleSubmit}
          type="button"
          disabled={isDisabled}> Continuer </button>
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}

        <HeaderMobile className="header-mobile-instance" logoPropertyDefault="/img/logo-1.svg" type="logo" />
      </div>
      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Inscription réussie'
        message='Votre compte a été créér avec succès. Pour confirmer votre adresse email, cliquez sur le lien que nous vous avons envoyé et entrez le code.'
      />
    </div>
  );
};
