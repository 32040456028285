import React, { useEffect, useState } from 'react'
import { Logo } from '../../components/Patients/Logo';
import { api_url } from '../../globarvariable';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { useDispatch } from 'react-redux';
import { logged } from '../../actions/logged';
import { ClipLoader, RingLoader } from 'react-spinners';
import axios from "axios";

const SignIn = () => {

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleAuthentication= () => {
      setLoading(true);
        const formData = {
            email: email.toLowerCase(),
            password: password
        };

        fetch(api_url+'api/auth/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
              if(data.access_token){
                localStorage.setItem('accessToken', data.access_token);
                if(rememberMe){
                  localStorage.setItem('tokenToRememberD', data.access_token);
                }
                axios.get(api_url+'api/users/me', {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${data.access_token}`,
                  }
                }).then((response) =>{
                  localStorage.setItem('doctor', response.data.first_name + ' '+response.data.last_name);
                  dispatch(logged());
                navigate('/medecin');
                })
                
              }
              else{
                setError('Impossible de se connecter car le nom d\'utilisateur et le mot de passe sont incorrects');
              }
                
            })
            .catch((error) => {
              console.error('Unaunthenticated', error);
            });

            setTimeout(() => {
              setLoading(false);
            }, 2000);
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        // Trigger the button click when Enter key is pressed
        handleAuthentication();
      }
    };

    const handleRememberMe = () => {
      if(rememberMe === false){
        setRememberMe(true);
      }
      else{
        setRememberMe(false);
        localStorage.removeItem('tokenToRememberD')
      }

    }

    useEffect(() => {
      const token = localStorage.getItem('tokenToRememberD');
      if (token  && localStorage.getItem('accessToken')) {
        localStorage.setItem('accessToken', token);
          dispatch(logged());
            navigate('/medecin');
      }
    }, []);


  return (
    <div>
       
        <section className="vh-100 bg-image" id="sign-up">
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-12 col-md-9 col-lg-7 col-xl-5"
                id="card-first-div"
              >
                <div className="card">
                  <div className="card-body p-5">
                  <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        />
                    <form>  
                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={handleChangeEmail}
                          autoComplete='email'
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Mot de passe"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={handlePassword}
                          onKeyDown={(e) => handleKeyPress(e)}
                          autoComplete='current-password'
                        />
                      </div>

                      <div className="row mb-4">
                        <div className="col d-flex justify-content-center">
                          <div className="form-check remember">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="remember"
                              checked={rememberMe}
                              onClick={handleRememberMe}
                            />
                            <label className="form-check-label" htmlFor="remember">
                              {" "}
                              Se souvenir de moi{" "}
                            </label>
                          </div>
                        </div>

                        <div className="col forgot-password">
                          <a href="/forgot-password">Mot de passe oublié?</a>
                        </div>
                      </div>
                      
                      <div className="form-outline mb-4 login">
                      {loading && <ClipLoader color="#123abc" loading={loading} />}
                      <button
                        type="button"
                        className="btn btn-primary btn-block mb-4"
                        onClick={handleAuthentication}
                      >
                        Se connecter
                      </button>
                      </div>
                      
                      <div className='error'>{error}</div>
                      <div className="text-center">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SignIn