// Commande `rafce` génère ceci:
import React, { useState } from 'react';
import ModalCentered from '../Modal/Modal';
import "./style.css";

const Footer = () => {

    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('');
    const [titleModal, setTitleModal] = useState('');

    const handleModal = (type) => {

        if(type == 'cgu'){
          setTextModal('Les présentes Conditions Générales d\'Utilisation (ci-après les "CGU") ont pour objet de définir les modalités d\'accès et d\'utilisation de APAORA. En accédant ou en utilisant le Site, vous acceptez sans réserve les présentes CGU. Si vous n\'acceptez pas ces CGU, nous vous invitons à ne pas utiliser le Site.')
          setTitleModal('Conditions Générales d\'Utilisation')
        }
        else if(type == 'mention'){
          setTextModal('Toute exploitation non autorisée du Site ou de l\'un de ses éléments sera considérée comme constitutive d\'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de la propriété intellectuelle.')
          setTitleModal('Mention légale')
        }
        else if(type == 'confidentialite'){
          setTextModal('Chez APAORA, nous nous engageons à protéger la confidentialité et la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez notre site')
          setTitleModal('Politiques de confidentialité')
        }
    
        setShowModal(true)
    
      }
    
      const onClose = () => {
        setShowModal(false);
      }
  
      

  return (
    <div className='footer patient'>
        <div className='footer-patient'>
            <p>Pour toute question ou suppression de votre compte, veuillez contacter notre support par e-mail:</p>
            <p className='support'><a href='mailto:support@observance.care'>support@observance.care</a>  <img src='/img/message-3.png' /></p>
            <ul className="footer-cgu"> <li onClick={() => handleModal('cgu')}> CGU </li> <li onClick={() => handleModal('mention')}> Mention légale </li> <li onClick={() => handleModal('confidentialite')}> Politique de confidentialité </li></ul>
        </div>
        <ModalCentered
            show={showModal}
            onHide={() => onClose()}
            title= {titleModal}
            subtitle= ''
            message= {textModal}
      />
    </div>
  );
};

export default Footer;
