/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  unit: boolean;
  state: "filled" | "active" | "initial";
  background: "white" | "transparent";
  type: "dropdown" | "text-area" | "classic";
  className: any;
  text: string;
  placeholder: string;
  onChange: (e) => void;
}

export const TextFieldBasic = ({ unit, state, background, type, className, text = "Label",onChange, placeholder}: Props): JSX.Element => {
  return (
    <div className={`text-field-basic ${type} ${state} ${background} ${className}`}>
      {(state === "filled" || (state === "initial" && type === "dropdown")) && (
        <div className="label">{["classic", "text-area"].includes(type) && <>{text}</>}</div>
      )}

      {(state === "filled" ||
        state === "initial" ||
        (state === "active" && type === "classic") ||
        (state === "active" && type === "text-area")) && <input
        type="text"
        id="lastname"
        className="form-control form-control-lg lastname"
        name="lastname"
        value={text}
        onChange={onChange}
        placeholder = {placeholder}
      />}

      {state === "active" && background === "white" && type === "dropdown" && (
        <>
          <div className="option">Option 1</div>
          <div className="div">Option 2</div>
          <div className="option-2">Option 3</div>
          <img className="line" alt="Line" src="/img/line-11.svg" />
          <img className="img" alt="Line" src="/img/line-12.svg" />
          <img className="line-2" alt="Line" src="/img/line-13.svg" />
        </>
      )}

      {state === "active" && type === "dropdown" && (
        <>
          <div className="icon-arrow-arrow" />
          <div className="label-2">{text}</div>
        </>
      )}

      {state === "active" && background === "transparent" && type === "dropdown" && (
        <>
          <div className="option">Option 1</div>
          <div className="div">Option 2</div>
          <div className="option-2">Option 3</div>
          <img className="line" alt="Line" src="/img/line-11.svg" />
          <img className="img" alt="Line" src="/img/line-12.svg" />
          <img className="line-2" alt="Line" src="/img/line-13.svg" />
        </>
      )}
    </div>
  );
};

TextFieldBasic.propTypes = {
  unit: PropTypes.bool,
  state: PropTypes.oneOf(["filled", "active", "initial"]),
  background: PropTypes.oneOf(["white", "transparent"]),
  type: PropTypes.oneOf(["dropdown", "text-area", "classic"]),
  text: PropTypes.string,
};
