import React, { useState } from 'react'
import { Logo } from '../../components/Patients/Logo/Logo'
import './style.css'
import { api_url } from '../../globarvariable';
import ClipLoader from 'react-spinners/ClipLoader';

const ForgottenPassword = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleClick = () => {

      setLoading(true);
        const formData = {
            email: email.toLowerCase()
        };

        fetch(api_url+'api/auth/forgot-password', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          }).then((response) => {
            if (response.ok) {
              setError(false);
              setIsEmailValid(true);
                setMessage('Un lien de réinitialisation a été envoyé à votre email');
                setLoading(false);
            } 
            else{
              setIsEmailValid(false);
              setError(true);
              setMessage('Ce compte n\'existe pas. Veuillez vérifier l\'adresse que vous avez saisi');
              setLoading(false);
            }
          })
         .catch(err=>{
            console.log(err)
            setError(true);
            setMessage('Une erreur a survenu lors de l\'opération. Veuillez réessayer s\'il vous plait');
            setLoading(false);
        })

    }
  return (
    <div>
        <section className="vh-100 bg-image" id="sign-up">
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-12 col-md-9 col-lg-7 col-xl-5"
                id="card-first-div"
              >
                <div className="card">
                  <div className="card-body p-5">
                  <Logo
          className="class logo-auth forgot-password"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        />
                    <p className="text-uppercase text-center">
                     <strong> Vous avez oublié votre mot de passe ? </strong>
                    </p>
                    <p className='reinitialise'>Vous pouvez réinitialiser votre mot de passe ici</p>
                    <form className='forgotten'>
                      <div className="form-outline mb-4">
                      <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={(e)=> setEmail(e.target.value)}
                        />
                      </div>
                      {isEmailValid === true && <p className='success'>{message}</p> }
                      {error === true && <p className='error'>{message}</p> }
                      {loading && <p className='loading'><ClipLoader color="#123abc" loading={loading} /></p>}
                      <button
                        type="button"
                        className="btn btn-primary btn-block mb-4 send-link"
                        onClick={handleClick}
                      >
                        Envoyer le lien
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ForgottenPassword